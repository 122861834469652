import { DATE_FORMAT } from "common/constants";
import CoronaRemedies from "common/icons/Corona_Remedies.png";
import MankindPharmaLogo from "common/icons/Mankind_pharma.png";
import SangkajLogo from "common/icons/Sangkaj_Group_WebApp_logo.png";
import AADLogo from "common/icons/aad-logo-wms.svg";
import EnneaLogo from "common/icons/ennea_logo.png";
import NovelLogo from "common/icons/novel_group.png";
import moment from "moment/moment";
import { SSF } from "xlsx";

export const validEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getLogo = () => {
  if (window.location.hostname === "sangkaj.enneasolutions.com") {
    return SangkajLogo;
  } else if (window.location.hostname === "novel.enneasolutions.com") {
    return NovelLogo;
  } else if (window.location.hostname === "aad.enneasolutions.com") {
    return AADLogo;
  } else if (window.location.hostname === "mankind.enneasolutions.com") {
    return MankindPharmaLogo;
  } else if (window.location.hostname === "corona.enneasolutions.com") {
    return CoronaRemedies;
  } else {
    return EnneaLogo;
  }
};

export const getTitle = () => {
  if (window.location.hostname === "sangkaj.enneasolutions.com") {
    return "Sangkaj Group";
  } else if (window.location.hostname === "novel.enneasolutions.com") {
    return "Novel Logitech";
  } else if (window.location.hostname === "aad.enneasolutions.com") {
    return "AAD Logitech";
  } else if (window.location.hostname === "mankind.enneasolutions.com") {
    return "Mankind Pharma";
  } else if (window.location.hostname === "corona.enneasolutions.com") {
    return "Corona Remedies";
  } else {
    return "Ennea WMS";
  }
};

export const parseDateFromSheet = (date, date1904) => {
  let momentFormattedDate = moment(date, "DD/MM/YYYY", true);

  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "DD.MM.YYYY", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "DD-MM-YYYY", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "YYYY/MM/DD", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "YYYY-MM-DD", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "YYYY.MM.DD", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "DD-MM-YY", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "DD/MM/YY", true);
  }
  if (!momentFormattedDate.isValid()) {
    momentFormattedDate = moment(date, "DD.MM.YY", true);
  }
  const formattedDate = SSF.format(DATE_FORMAT.YYYY_MM_DD, date, {
    date1904: date1904
  });
  return momentFormattedDate.isValid()
    ? momentFormattedDate.format(DATE_FORMAT.YYYY_MM_DD)
    : formattedDate;
};

export const parseExcelDate = (excelDate) => {
  const epoch0 = new Date("1899-12-31"); // Excel epoch in JavaScript
  const excelLeapYearOffset = excelDate >= 60 ? 1 : 0; // Excel leap year bug adjustment

  const date = new Date(
    epoch0.getTime() + (excelDate - excelLeapYearOffset) * 24 * 60 * 60 * 1000
  );
  date.setUTCMilliseconds(0);
  const formattedDate = moment(date).format(DATE_FORMAT.YYYY_MM_DD);

  return formattedDate;
};

export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return dateString.match(regEx) != null;
};
