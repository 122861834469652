export const DATE_FORMAT = {
  DD_MM_YYYY: "DD-MM-YYYY",
  YYYY_MM_DD: "YYYY-MM-DD",
  DDMMYYYY: "DD/MM/YYYY",
  dmy_with_comma: "DD MMM, YY",
  YYYY_MM_DD_HH_mm_ss: "YYYY-MM-DD HH:mm:ss",
  yyyy_MM_dd: "yyyy-MM-dd"
};

export const INVOICE_STATUSES = {
  PENDING: "PENDING",
  PICKED: "PICKED",
  PACKED: "PACKED",
  DISPATCHED: "DISPATCHED",
  CANCELLED: "CANCELLED",
  DELIVERED: "DELIVERED"
};

export const ORDER_STATUSES = {
  PENDING: "PENDING",
  PICKED: "PICKED",
  CANCELLED: "CANCELLED"
};

export const RETURN_STATUSES = {
  RECEIVED: "RECEIVED",
  PROCESSED: "PROCESSED",
  CHECKED: "CHECKED",
  CANCELLED: "CANCELLED"
};

export const ROLE_KEYS = {
  ADMIN: "ADMIN",
  BILLER: "BILLER",
  GROUP_ADMIN: "GROUP_ADMIN",
  CLEANER: "CLEANER",
  SUPERVISOR: "SUPERVISOR",
  CHECKER: "CHECKER",
  WORKER: "WORKER",
  SECURITY: "SECURITY"
};
