import { Button as AntdButton, Table as AntdTable, Modal } from "antd";
import COLORS from "common/constants/Colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const MultiSelectFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 187.5% */
  padding: 0 16px;
  height: 56px;
  width: 160px;
  column-gap: 12px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 87, 84, 0.1);
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  box-shadow: 0px 4px 6px -3px rgba(154, 188, 187, 0.6);
  cursor: pointer;
  @media screen and (max-width: 992px) {
    height: 38px;
    padding: 0 12px;
    flex: 1;
    gap: 0;
  }
`;
export const MultiSelectContentContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: #005754;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
  @media screen and (max-width: 992px) {
    font-size: 12px;
    column-gap: 8px;
  }
`;

export const MultiSelectCustomMenu = styled.div`
  padding: 12px;
  margin-top: 7px;
  border-radius: 4px;
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  stroke-width: 1px;
  stroke: rgba(0, 87, 84, 0.1);
  filter: drop-shadow(0px 4px 6px rgba(154, 188, 187, 0.6));
  @media screen and (max-width: 992px) {
    padding: 8px;
    margin-top: 0;
  }
`;

export const MultiSelectMenuTitle = styled.div`
  color: #005754;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const MultiSelectMenuItem = styled.div`
  display: flex;
  column-gap: 8px;
  background: ${(props) =>
    props.highlight
      ? "#ebebe9"
      : "linear-gradient(180deg, #fff 0%, #f5f5f5 100%)"};
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 87, 84, 0.1);
  white-space: pre-wrap;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    margin-top: 5px;
    padding: 6px;
  }
`;

export const MultiSelectLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: #2e2e2e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const MultiSelectImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    width: 20px;
    height: 20px;
  }
`;
export const MultiSelectImage = styled.img`
  width: 18px;
  height: 18px;
`;

export const LinkElement = styled(Link)`
  margin: 0 20px 0 0;
`;

export const CommonHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin: 20px 0;
`;

export const Button = styled(AntdButton)`
  background: "white";
  color: ${COLORS.primary_blue};
  font-size: 14px;
  font-weight: bold;
  min-width: 60px;
  padding: 0.25em 1em;
  border: 1.5px solid ${COLORS.primary_blue};
  border-radius: 5px;
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background: ${COLORS.primary_blue};
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      :hover {
        background: ${COLORS.primary_blue};
        color: white;
      }
    `}
`;

export const DynamicColorButton = styled.button`
  background-color: ${(props) => props?.color ?? "black"};
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  border: 0px;
  cursor: pointer;
`;

export const ColorAntdButton = styled(Button)`
  border-color: ${(props) => props.color || COLORS.primary_blue};
  color: ${(props) => props.color || COLORS.primary_blue};
  :hover {
    border-color: ${(props) => props.color || COLORS.primary_blue};
    color: ${(props) => props.color || COLORS.primary_blue};
  }
`;

export const CustomAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
`;

export const CustomAntdTable = styled(AntdTable)`
  .ant-table-container {
    border: 1px solid ${COLORS.primary_grey} !important;
  }
  .ant-table-thead > tr > th {
    background-color: white;
    text-transform: capitalize;
    border-bottom: 1px solid ${COLORS.primary_grey};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${COLORS.primary_grey};
    padding: 10px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: white;
  }
  .ant-table-container table > tbody > tr:last-child > td {
    border-bottom: 0px !important;
  }
`;

export const StyledAntdTable = styled(AntdTable)`
  .ant-table-container {
    border: 1px solid rgba(0, 87, 84, 0.2) !important;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    background: linear-gradient(
        0deg,
        rgba(1, 191, 185, 0.05) 0%,
        rgba(1, 191, 185, 0.05) 100%
      ),
      #fff;
    padding: 10px;
    color: #2e2e2e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    border-right: 1px solid rgba(0, 87, 84, 0.2);
    white-space: nowrap;
  }
  .ant-table-tbody > tr > td {
    background: #fff;
    border-top: 1px solid rgba(0, 87, 84, 0.2);
    border-right: 1px solid rgba(0, 87, 84, 0.2);
    color: #2e2e2e;
    padding: 0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
  .ant-table-tbody > tr.ant-table-row > td.ant-table-cell-row-hover {
    background: #e2eafc;
    border-bottom: 1px solid rgba(0, 87, 84, 0.2);
    border-right: 1px solid rgba(0, 87, 84, 0.2);
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #a3c4f3;
    color: #2e2e2e;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-cell-row-hover {
    background: #e2eafc;
    color: #2e2e2e;
  }
`;

export const StyledAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    border: 1.5px solid rgba(0, 87, 84, 0.4);
    padding: 0;
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 87, 84, 0.2);
  }
  .ant-modal-title {
    color: #005754;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

export const PrimaryButton = styled.button`
  padding: ${(props) => (props.small === "true" ? "0 12px" : "12px 40px")};
  font-size: ${(props) => (props.small === "true" ? "12px" : "16px")};
  ${(props) => {
    if (props.type === "danger") {
      return css`
        border-radius: 4px;
        border: 1px solid rgba(142, 21, 21, 0.2);
        background: linear-gradient(94deg, #ee9892 0%, #e55f56 100%);
      `;
    }
    return css`
      background: linear-gradient(
        275deg,
        #00bfb9 0%,
        rgba(0, 191, 185, 0.65) 100%
      );
      border-radius: 6px;
      border: 1.5px solid rgba(0, 148, 143, 0.3);
    `;
  }};
  opacity: ${(props) => props.disabled && "0.4"};
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 0px 4px 6px -3px rgba(154, 188, 187, 0.6);
  @media screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.small === "true"
        ? css`
            width: max-content;
            padding: 0 4px;
            height: 24px;
            font-size: 9px;
          `
        : css`
            width: 48%;
            padding: 12px 40px;
            height: 38px;
            font-size: 12px;
          `}
    white-space: nowrap;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
`;

export const Table = styled(AntdTable)`
  .ant-table table {
    border-collapse: collapse;
    margin: 20px 0px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    > :last-child {
      border: 0px solid black;
      border-bottom: 1.2px solid #d4d4d4;
    }
  }
  thead {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  tr {
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const ButtonFloatRightContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoginFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  width: 25%;
  margin: 0 0 0 10%;
`;
export const AbsoluteLeftContainer = styled.section`
  position: absolute;
  right: 0;
  padding: 2em;
`;

export const LargeButton = styled(Button)`
  height: 60px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  border: 1.5px solid ${COLORS.primary_blue};
  border-radius: 6px;
  margin-top: 20px;
  :disabled {
    color: white;
    background: ${COLORS.primary_blue};
    opacity: 0.7;
    border: 1.5px solid ${COLORS.primary_blue};
    border-radius: 6px;
    :hover {
      background: ${COLORS.primary_blue};
      color: white;
    }
  }
`;

export const InputElement = styled.input`
  height: 50px;
  width: 94%;
  border: 1.5px solid ${COLORS.primary_blue};
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0px 10px;
  outline: none;
  ${(props) =>
    props.error &&
    css`
      width: 94%;
      border-color: red;
    `}
`;

export const FileInputElement = styled.input`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_blue};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.disabled_grey};
      border-color: ${COLORS.disabled_grey};
    `}
`;

export const ImageElement = styled.img`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_blue};
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const TitleElement = styled.h1`
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0;
`;

export const ErrorElement = styled.div`
  font-size: 12px;
  color: red;
`;

export const PageHeader = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: black;
`;

export const PageContainer = styled.div`
  padding: 0px 20px;
`;

export const IconButton = styled.button`
  background: white;
  border: 0px;
  cursor: pointer;
  padding-left: 0px;
`;

export const Separator = styled.hr`
  margin-top: 20px;
  margin-left: 0px;
  width: 90%;
  border: 1px solid ${COLORS.primary_grey};
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
`;

export const CommonTableContainer = styled.div`
  margin: 20px 0px;
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 10px;
  width: ${(props) => props.width || "90%"};
  max-height: ${(props) => props.maxHeight || "70vh"};
  overflow: auto;
  > :last-child {
    border: 0px solid black;
  }
`;

export const CommonTableInput = styled.input`
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 4px;
  text-align: center;
  width: 85%;
`;

export const CommonSearchInput = styled.input`
  color: ${COLORS.primary_blue};
  ::placeholder {
    color: ${COLORS.primary_blue};
  }
  width: 175px;
  border: 1px solid ${COLORS.primary_blue};
  border-radius: 4px;
  height: 40px;
  padding: 0px 10px;
  &:focus {
    border: 2px solid ${COLORS.primary_blue} !important;
    outline: none;
  }
`;

export const CommonTableHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const CommonSelectHeading = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;

export const CommonTableHeader = styled.div`
  display: grid;
  padding: 20px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
  position: sticky;
  top: 0px;
  background: white;
`;

export const CommonTableRowContainer = styled.div`
  display: grid;
  padding: 15px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
`;

export const WelcomeTextElement = styled.div`
  font-size: 40px;
  font-weight: bold;
  align-self: center;
`;
export const EnneaLogoElement = styled.img`
  height: 82px;
  width: 140px;
  align-self: center;
`;
export const SignInImageElement = styled.img`
  margin-left: 10%;
  align-self: center;
`;
export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

// Responsive styles

export const HideSmallScreenComponent = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const HideBigScreenComponents = styled.div`
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;
