import { ConfigProvider } from "antd";
import ErrorBoundary from "common/components/ErrorBoundary";
import AuthContextProvider from "common/contexts/AuthContext";
import Login from "common/routes/Login";
import { getTitle } from "common/utils";
import { lazy, Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppLayout = lazy(() => import("common/components/AppLayout"));
const Alerts = lazy(() => import("common/routes/Alerts"));
const Dashboard = lazy(() => import("common/routes/Dashboard"));
const Invoices = lazy(() => import("common/routes/Invoices"));
const Companies = lazy(() => import("common/routes/Companies"));
const Timings = lazy(() => import("common/routes/Timings"));
const Vehicles = lazy(() => import("common/routes/Vehicles"));
const Parties = lazy(() => import("common/routes/Parties"));
const Notifications = lazy(() => import("common/routes/Notifications"));
const InvoiceUpdate = lazy(() => import("common/routes/InvoiceUpdate"));
const Temperature = lazy(() =>
  import("common/routes/Maintenance/Supervisor/Temperature")
);
const WHCleaning = lazy(() =>
  import("common/routes/Maintenance/Worker/WHCleaning")
);
const WHPestControl = lazy(() =>
  import("common/routes/Maintenance/Worker/WHPestControl")
);
const Orders = lazy(() => import("common/routes/Orders"));
const Summary = lazy(() => import("common/routes/Summary"));
const GatePasses = lazy(() => import("common/routes/GatePasses"));
const Transports = lazy(() => import("common/routes/Transports"));
const Products = lazy(() => import("common/routes/Products"));
const Inventory = lazy(() => import("common/routes/Inventory"));
const ReturnsManagement = lazy(() => import("common/routes/ReturnsManagement"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

function App() {
  useEffect(() => {
    document.title = getTitle();
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4992CD"
        }
      }}
    >
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ErrorBoundary>
              <Suspense fallback={<div>Loading your content...</div>}>
                <Routes>
                  <Route path="/" element={<AppLayout />}>
                    <Route index path="alerts" element={<Alerts />} />
                    <Route index path="dashboard" element={<Dashboard />} />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="companies" element={<Companies />} />
                    <Route path="temperature" element={<Temperature />} />
                    <Route path="cleaning" element={<WHCleaning />} />
                    <Route path="wh-pest-control" element={<WHPestControl />} />
                    <Route path="orders/*" element={<Orders />} />
                    <Route path="summary" element={<Summary />} />
                    <Route path="gatePass" element={<GatePasses />} />
                    <Route path="transports" element={<Transports />} />
                    <Route path="products" element={<Products />} />
                    <Route path="inventory" element={<Inventory />} />
                    <Route path="parties" element={<Parties />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="returns/*" element={<ReturnsManagement />} />
                    <Route path="timings" element={<Timings />} />
                    <Route path="vehicles" element={<Vehicles />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="invoice-update/:id"
                    element={<InvoiceUpdate />}
                  />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthContextProvider>
    </ConfigProvider>
  );
}

export default App;
