const COLORS = {
  primary_blue: "#4992CD",
  light_blue: "#4992cd66",
  primary_grey: "#d4d4d4",
  disabled_grey: "#EDEDED",
  pending: "rgb(255, 99, 132)",
  picked: "rgb(153, 102, 255)",
  packed: "rgb(53, 162, 235)",
  dispatched: "rgb(75, 192, 192)",
  cancelled: "#FF7074",
  received: "#cbf3f0",
  checked: "#d8d8d8",
  processed: "#88d4ab",
  midnight_green: "#005754",
  pale_cyan: "#f3fdfc",
  wood_rush: "#3e3d3a",
  salmon_pink: "#FF91A4",
  dusty_grey: "#999999"
};

export default COLORS;
