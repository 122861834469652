import { message, Spin } from "antd";
import { ROLE_KEYS } from "common/constants";
import APIConstants from "common/constants/APIConstants";
import COLORS from "common/constants/Colors";
import { useAuthContext } from "common/contexts/AuthContext";
import LoginImage from "common/icons/LoginImage.jpg";
import LoginIcon from "common/icons/LoginIcon.svg";
import PasswordIcon from "common/icons/PasswordGreen.svg";
import UserIcon from "common/icons/UserGreen.svg";
import ViewPassword from "common/icons/ViewPasswordGreen.svg";
import { getLogo } from "common/utils";
import { api } from "common/utils/APIMethods";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Logo = getLogo();

const Container = styled.div`
  height: 100vh;
  display: flex;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 35vw;
  background: linear-gradient(100deg, #f4fcfc 0%, #e2f8f7 100%);
  @media screen and (max-width: 992px) {
    width: 100vw;
    padding: 0;
  }
`;
const BackgroundImage = styled.img`
  width: 65vw;
  height: 100vh;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const Header = styled.div`
  color: #005754;
  font-family: Poppins;
  font-size: 62.618px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -2.505px;
  @media screen and (max-width: 992px) {
    font-size: 42px;
  }
`;

const LogoImage = styled.img`
  width: 350px;
  height: 150px;
  flex-shrink: 0;
  margin-bottom: 45px;
  @media screen and (max-width: 992px) {
    width: 250px;
    height: 100px;
    margin-bottom: 45px;
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 18px -6px rgba(1, 191, 185, 0.4);
  color: #292929;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 142.857% */
  width: calc(30.75rem * 0.7352);
  height: calc(4.875rem * 0.7352);
  @media screen and (max-width: 992px) {
    width: 90%;
    gap: 0;
  }
`;

const InputElement = styled.input`
  border: 0px;
  outline: none;
  color: #292929;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  ::placeholder {
    color: ${(props) =>
      props?.error ? COLORS.salmon_pink : COLORS.dusty_grey};
  }
  @media screen and (max-width: 992px) {
    margin-left: 6px;
  }
`;

const Img = styled.img`
  width: 28px;
  height: 28px;
  padding-left: 21px;
  @media screen and (max-width: 992px) {
    padding-left: 12px;
  }
`;

const ViewPasswordImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: auto;
  cursor: pointer;
  padding-right: 21px;
`;

const LoginButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 142.857% */
  border-radius: 6px;
  border: 1.5px solid rgba(0, 148, 143, 0.3);
  background: linear-gradient(275deg, #00bfb9 0%, rgba(0, 191, 185, 0.65) 100%);
  box-shadow: 0px 6px 9px -4.5px rgba(0, 191, 185, 0.6);
  width: calc(30.75rem * 0.7352);
  height: calc(4.875rem * 0.7352);
  flex-shrink: 0;
  padding: 24px;
  cursor: pointer;
  margin-top: 20px;
  .ant-spin-dot-item {
    background-color: #fff;
  }
  @media screen and (max-width: 992px) {
    width: 90%;
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const { login = "" } = APIConstants;
  const [searchParams] = useSearchParams();
  const apiToken = searchParams.get("apiToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: ""
    }
  });

  const { isLoading: tokenLoginIsLoading } = useQuery(
    "loginWithToken",
    () =>
      api({
        url: APIConstants.loginToken,
        method: "POST",
        body: {
          token: apiToken
        },
        checkAuth: false
      }),
    {
      enabled: !!apiToken,
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      },
      onError: (error) => {
        message.error(error?.message);
      }
    }
  );

  const navigateToScreen = (userRole, response) => {
    switch (userRole) {
      case ROLE_KEYS.ADMIN:
      case ROLE_KEYS.GROUP_ADMIN:
        navigate("/dashboard", { replace: true });
        return;
      case ROLE_KEYS.CLEANER:
      case ROLE_KEYS.SUPERVISOR:
        navigate("/cleaning", { replace: true });
        return;
      case ROLE_KEYS.CHECKER:
        navigate("/returns", { replace: true });
        return;
      case ROLE_KEYS.SECURITY:
        navigate("/timings", { replace: true });
        return;
      default:
        if (!response?.invoiceStatuses?.length) {
          navigate("/returns", { replace: true });
          return;
        }
        navigate("/invoices", { replace: true });
        return;
    }
  };

  const { isLoading, mutate } = useMutation(
    (formData) =>
      api({
        url: login,
        method: "POST",
        body: formData
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigateToScreen(response.role, response);
      },
      onError: (response) => {
        message.error(response?.message ?? "Check Username OR Password");
      }
    }
  );

  const onSubmit = (formData) => {
    mutate(formData);
  };

  const onChangeShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleLogin = () => {
    if (errors?.username) {
      message.error(errors.username.message);
    } else if (errors?.password) {
      message.error(errors.password.message);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  if (tokenLoginIsLoading) {
    return <Spin />;
  }

  return (
    <Container>
      <BackgroundImage src={LoginImage} />
      <Card>
        <Header>Welcome to</Header>
        <LogoImage src={Logo} />
        <InputContainer>
          <Img src={UserIcon} alt="user" />
          <InputElement
            {...register("username", {
              required: "Enter your username"
            })}
            placeholder="Enter your username"
            error={errors?.username}
            onKeyPress={handleEnterKeyPress}
            onBlur={(e) => setValue("username", e.target.value.trim())}
          />
        </InputContainer>
        <InputContainer>
          <Img src={PasswordIcon} alt="password" />
          <InputElement
            {...register("password", {
              required: "Enter your password"
            })}
            type={showPassword ? "text" : "password"}
            error={errors?.password}
            placeholder="Enter your password"
            onKeyPress={handleEnterKeyPress}
          />
          <ViewPasswordImage
            password
            src={ViewPassword}
            alt="viewPassword"
            onClick={onChangeShowPassword}
          />
        </InputContainer>
        <LoginButton onClick={handleLogin}>
          Login
          {isLoading ? <Spin /> : <Img src={LoginIcon} alt="LOGIN" />}
        </LoginButton>
      </Card>
    </Container>
  );
};

export default Login;
