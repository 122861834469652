import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";

const AuthContext = createContext();

const initialState = {
  accessToken: window.sessionStorage.getItem("accessToken"),
  username: window.sessionStorage.getItem("username"),
  role: window.sessionStorage.getItem("role"),
  companyId: window.sessionStorage.getItem("companyId"),
  id: window.sessionStorage.getItem("id"),
  name: window.sessionStorage.getItem("name"),
  invoiceStatuses: JSON.parse(window.sessionStorage.getItem("invoiceStatuses")),
  billerInvoiceConfig: window.sessionStorage.getItem("billerInvoiceConfig"),
  billerOrderConfig: window.sessionStorage.getItem("billerOrderConfig"),
  billerCreditNoteAlertConfig: window.sessionStorage.getItem(
    "billerCreditNoteAlertConfig"
  ),
  billerPaymentAlertConfig: window.sessionStorage.getItem(
    "billerPaymentAlertConfig"
  )
};

function authReducer(state, action) {
  switch (action.type) {
    case "onLogin": {
      const {
        accessToken,
        username,
        role,
        name,
        id,
        companyId,
        invoiceStatuses = [],
        billerInvoiceConfig,
        billerOrderConfig,
        billerCreditNoteAlertConfig,
        billerPaymentAlertConfig
      } = action.payload;
      window.sessionStorage.setItem("accessToken", accessToken);
      window.sessionStorage.setItem("username", username);
      window.sessionStorage.setItem("role", role);
      window.sessionStorage.setItem("name", name);
      window.sessionStorage.setItem("id", id);
      window.sessionStorage.setItem("companyId", companyId);
      window.sessionStorage.setItem(
        "invoiceStatuses",
        JSON.stringify(invoiceStatuses)
      );
      window.sessionStorage.setItem("billerInvoiceConfig", billerInvoiceConfig);
      window.sessionStorage.setItem("billerOrderConfig", billerOrderConfig);
      window.sessionStorage.setItem(
        "billerCreditNoteAlertConfig",
        billerCreditNoteAlertConfig
      );
      window.sessionStorage.setItem(
        "billerPaymentAlertConfig",
        billerPaymentAlertConfig
      );
      return {
        ...state,
        accessToken,
        username,
        role,
        name,
        id,
        companyId,
        invoiceStatuses,
        billerOrderConfig,
        billerInvoiceConfig,
        billerCreditNoteAlertConfig,
        billerPaymentAlertConfig
      };
    }
    case "onLogout": {
      window.sessionStorage.clear();
      return {
        accessToken: null,
        username: null,
        role: null,
        name: null,
        id: null,
        companyId: null,
        invoiceStatuses: null,
        billerInvoiceConfig: null,
        billerOrderConfig: null,
        billerCreditNoteAlertConfig: null,
        billerPaymentAlertConfig: null
      };
    }
    default:
      throw new Error("Invalid action type");
  }
}

export default function AuthContextProvider({ children }) {
  const [authConfig, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    global.authDispatch = dispatch;
  }, []);

  const authContext = useMemo(
    () => ({
      authConfig,
      dispatch
    }),
    [authConfig]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
